import { ReactNode, createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import axios from "axios";
import {
  AptosIcon,
  CkBTCIcon,
  ICPIcon,
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  VenomIcon,
} from "svgIcon";

export const ChainSUI = "SUI";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainSolana = "Solana";
export const ChainSOL = "SOL";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainICP = "ICP";
export const ChainCKBTC = "ckBTC";
export const ChainCKTestBTC = "ckTESTBTC";

export const setStorageData = (key: any, data: any) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export function extractID(str: any) {
  const match = str.match(/(pos|paylink)=(\d+)/);
  if (match) {
    const [, name, id] = match; // Destructuring the matched array
    return { name, id };
  }
  return null;
}

export const decodeContent = (pos: string) => {
  return atob(pos); // Use btoa to base64 encode the string
};

export const encodeContent = (pos: string) => {
  return btoa(pos);
};

export function shortenValue(value: any): any {
  return parseFloat(value.toFixed(4));
}

export const dateAndTimeFormat = () => {
  const timestamp = Date.now();
  const humanReadableDate = new Date(timestamp).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  return humanReadableDate;
};

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const selectedChainIcon = (getCoinName: any) => {
  switch (getCoinName) {
    case "SUI": {
      return <SuiIcon />;
    }
    case "Aptos": {
      return <AptosIcon />;
    }
    case "Venom": {
      return <VenomIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "ICP": {
      return <ICPIcon />;
    }
    case "ckBTC": {
      return <CkBTCIcon />;
    }
    case "ckTESTBTC": {
      return <CkBTCIcon />;
    }
  }
};

export const checkCoin = (getCoinName: any, getDigest: any) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/txblock/${getDigest}?network=testnet`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/txn/${getDigest}?network=testnet`;
    }
    case "Venom": {
      return ` https://testnet.venomscan.com/transactions/${getDigest}`;
    }
    case "SOL": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getDigest}`;
    }
  }
};

/* https://api.coinbase.com */
export const getCoinAmount = async (setManageChainAmount: any) => {
  try {
    const solanaResponse = await axios.get(
      "https://api.coinbase.com/v2/prices/Sol-USD/buy"
    );
    if (
      solanaResponse.data &&
      solanaResponse.data &&
      solanaResponse.data.data &&
      solanaResponse.data.data.amount
    ) {
      setManageChainAmount((prevAmount: any) => ({
        ...prevAmount,
        solana: parseFloat(solanaResponse.data.data.amount),
      }));
    }

    const suiResponse = await axios.get(
      "https://api.coinbase.com/v2/prices/Sui-USD/buy"
    );
    if (
      suiResponse.data &&
      suiResponse.data &&
      suiResponse.data.data &&
      suiResponse.data.data.amount
    ) {
      setManageChainAmount((prevAmount: any) => ({
        ...prevAmount,
        sui: parseFloat(suiResponse.data.data.amount),
      }));
    }
  } catch (error: any) {}
};

/* https://api.coingecko.com */
// export const getCoinAmount = async (setManageChainAmount: any) => {
//   try {
//     const aptosResponse = await axios.get(
//       "https://api.coingecko.com/api/v3/simple/price?ids=aptos&vs_currencies=usd"
//     );
//     if (
//       aptosResponse.data &&
//       aptosResponse.data.aptos &&
//       aptosResponse.data.aptos.usd
//     ) {
//       setManageChainAmount((prevAmount: any) => ({
//         ...prevAmount,
//         aptos: aptosResponse.data.aptos.usd,
//       }));
//     }

//     const solanaResponse = await axios.get(
//       "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
//     );
//     if (
//       solanaResponse.data &&
//       solanaResponse.data.solana &&
//       solanaResponse.data.solana.usd
//     ) {
//       setManageChainAmount((prevAmount: any) => ({
//         ...prevAmount,
//         solana: solanaResponse.data.solana.usd,
//       }));
//     }

//     const ckbtcResponse = await axios.get(
//       "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
//     );
//     if (
//       ckbtcResponse.data &&
//       ckbtcResponse.data.bitcoin &&
//       ckbtcResponse.data.bitcoin.usd
//     ) {
//       setManageChainAmount((prevAmount: any) => ({
//         ...prevAmount,
//         ckbtc: ckbtcResponse.data.bitcoin.usd,
//       }));
//     }

//     const suiResponse = await axios.get(
//       "https://api.coingecko.com/api/v3/simple/price?ids=sui&vs_currencies=usd"
//     );
//     if (suiResponse.data && suiResponse.data.sui && suiResponse.data.sui.usd) {
//       setManageChainAmount((prevAmount: any) => ({
//         ...prevAmount,
//         sui: suiResponse.data.sui.usd,
//       }));
//     }
//   } catch (error: any) {}
// };

export const toastMessage = (data: any) =>
  toast(data, {
    position: "bottom-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export function shortenAddress(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

interface GlobalMerchantContextType {
  globalMerchantId: string | null;
  setGlobalMerchantId: (merchantId: string | null) => void;
}

const GlobalMerchantContext = createContext<
  GlobalMerchantContextType | undefined
>(undefined);

export function GlobalMerchantProvider({ children }: { children: ReactNode }) {
  const [globalMerchantId, setGlobalMerchantId] = useState<string | null>(null);

  return (
    <GlobalMerchantContext.Provider
      value={{ globalMerchantId, setGlobalMerchantId }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}
