import React, { useEffect, useState } from "react";
import axios from "axios";
import SuiWalletConnect from "components/ConnectToWallet/sui";
import { useWalletKit } from "@mysten/wallet-kit";
import { Button } from "antd";
import { TransactionBlock } from "@mysten/sui.js";
import { WalletSelector } from "@aptos-labs/wallet-adapter-ant-design";
import { Network, Provider, Types } from "aptos";
import { NetworkName, useWallet } from "@aptos-labs/wallet-adapter-react";
import { Principal } from "@dfinity/principal";
import { idlFactory } from "components/ConnectToWallet/icp/ledger.did";
import { baseURL } from "utils/env";
import { PendingLoader } from "svgIcon";
import {
  ChainAptos,
  ChainCKBTC,
  ChainCKTestBTC,
  ChainSUI,
  dateAndTimeFormat,
  decodeContent,
  extractID,
  toastMessage,
} from "utils/helper";
import IcpWalletConnect from "components/ConnectToWallet/icp";
import { WalletOutlined } from "@ant-design/icons";

interface PayWithWalletState {
  amount: string;
  blockchain: string;
  coin: string;
  coin_type: string;
  description: string;
  merchant_email: string;
  merchant_name: string;
  order_id: number;
  receiver_address: string;
  sender_address: string; // Make sure 'sender_address' is present if it's part of your data
  status: string;
  txn_digest: string;
  url: string;
}

const DEVNET_CLIENT = new Provider(Network.DEVNET);
const TESTNET_CLIENT = new Provider(Network.TESTNET);

const PayWithWallet = ({
  cusEmail,
  generateJsonContent,
  qrContentTitle,
  setIsPaymentSuccess,
  setManageDigest,
  merchantContent,
}: any) => {
  const getIsConnected = localStorage.getItem("wallet-kit:last-wallet");
  // const getWalletAmount = localStorage.getItem("amount");
  // const getMerchantEmail = "demo@Pocketpay.com";
  const [isLoading, setLoading] = useState(false);
  const [isICPConnected, setIsICPConnected] = useState<boolean>(false);
  const { signAndExecuteTransactionBlock, currentAccount } = useWalletKit();
  const { account, connected, network, wallet } = useWallet();
  const { disconnect, signAndSubmitTransaction, signMessage } = useWallet();
  const [orderContent, setOrderContent] = useState<PayWithWalletState | null>(
    null
  );

  const onTerminalStore = async (content: any, digest: any, sender: any) => {
    const getDateAndTime = await dateAndTimeFormat();
    const merchantObject = {
      amount: content.amount,
      name: " ",
      client_address:
        "0x9d655392521726d0eb26915670f7a37fe78b6fe001d133280ddaf57e4428aae1",
      coin: content.blockchain,
      coin_amount: content.amount,
      payment_id: content.order_id,
      date_time: `${getDateAndTime}`,
      digest: digest,
      status: "completed",
      order_type: "Terminal",
      merchant_id: merchantContent.merchant_id,
      merchant_email: merchantContent.merchant_email,
      merchant_name: merchantContent.merchant_name,
      merchant_address: " ",
      network: " ",
      business_name: "demo business",
      customer_email: cusEmail,
    };
    if (merchantObject) {
      try {
        axios
          .post(`${baseURL}merchant_orders`, merchantObject)
          .then((response) => {
            setManageDigest(digest);
            setLoading(false);
            setIsPaymentSuccess(true);
          });
      } catch (error) {}
    }
  };

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    // setQrStatus(5);
    onTerminalStore(content, digest, sender);
  };

  const aptosClient = (network: any) => {
    if (network === NetworkName.Devnet.toLowerCase()) {
      return DEVNET_CLIENT;
    } else if (network === NetworkName.Testnet.toLowerCase()) {
      return TESTNET_CLIENT;
    } else if (network === NetworkName.Mainnet.toLowerCase()) {
      throw new Error("Please use devnet or testnet for testing");
    } else {
      throw new Error(`Unknown network: ${network}`);
    }
  };

  const sendAptosTxn = async () => {
    setLoading(true);
    try {
      const getAmount = generateJsonContent?.amount;
      const payload: Types.TransactionPayload = {
        type: "entry_function_payload",
        function:
          "0xe6a922bc6c8c35549d079d842c3ef4f1fbddcb766db07a889ac5c6d81cb91f12::tx::create_tx",
        type_arguments: ["0x1::aptos_coin::AptosCoin"],
        arguments: [
          generateJsonContent?.order_id, //order id
          generateJsonContent?.merchant_address, // receiver
          10000000, // amount - 100000000
          generateJsonContent?.merchant_name, // merchant - Pocketpay
        ],
      };
      const response = await signAndSubmitTransaction(payload);
      await aptosClient(network?.name.toLowerCase()).waitForTransaction(
        response.hash
      );
      if (response.hash) {
        onPaymentSuccess(
          generateJsonContent,
          response.hash,
          response.hash,
          generateJsonContent?.order_id
        );
      }
    } catch (error) {
      setLoading(false);
      toastMessage(error);
    }
  };

  const sendIcpTxn = async () => {
    setLoading(true);
    try {
      const principalId = await window.ic.bitfinityWallet.getPrincipal();
      const manageAmount = Math.floor(generateJsonContent.amount * 100000000);
      const actor: any = await window.ic.bitfinityWallet.createActor({
        canisterId: "mxzaz-hqaaa-aaaar-qaada-cai",
        interfaceFactory: idlFactory,
      });
      const balance = await actor.icrc1_balance_of({
        owner: principalId,
        subaccount: [],
      });
      if (Number(balance) > manageAmount) {
        const transferCkBTC = await actor.icrc1_transfer({
          to: {
            owner: Principal.fromText(generateJsonContent.merchant_address!),
            subaccount: [],
          },
          fee: [],
          memo: [],
          from_subaccount: [],
          created_at_time: [],
          amount: BigInt(manageAmount),
        });
        if (transferCkBTC && transferCkBTC.Ok) {
          const okValueAsString = transferCkBTC.Ok.toString();
          onPaymentSuccess(
            generateJsonContent,
            okValueAsString,
            okValueAsString,
            generateJsonContent?.order_id
          );
        }
      } else {
        setLoading(false);
        toastMessage("You dont have sufficient balance");
      }
    } catch (error) {
      setLoading(false);
      toastMessage(error);
    }
  };

  const sendTestIcpTxn = async () => {
    setLoading(true);
    try {
      const principalId = await window.ic.bitfinityWallet.getPrincipal();
      const manageAmount = Math.floor(generateJsonContent.amount * 100000000);
      const actor: any = await window.ic.bitfinityWallet.createActor({
        canisterId: "mc6ru-gyaaa-aaaar-qaaaq-cai",
        interfaceFactory: idlFactory,
      });
      const balance = await actor.icrc1_balance_of({
        owner: principalId,
        subaccount: [],
      });
      if (Number(balance) > manageAmount) {
        const transferCkBTC = await actor.icrc1_transfer({
          to: {
            owner: Principal.fromText(generateJsonContent.merchant_address!),
            subaccount: [],
          },
          fee: [],
          memo: [],
          from_subaccount: [],
          created_at_time: [],
          amount: BigInt(manageAmount),
        });
        if (transferCkBTC && transferCkBTC.Ok) {
          const okValueAsString = transferCkBTC.Ok.toString();
          onPaymentSuccess(
            generateJsonContent,
            okValueAsString,
            okValueAsString,
            generateJsonContent?.order_id
          );
        }
      } else {
        setLoading(false);
        toastMessage("You dont have sufficient balance");
      }
    } catch (error) {
      setLoading(false);
      toastMessage(error);
    }
  };

  const sendSUITxn = async () => {
    setLoading(true);
    try {
      const tx = new TransactionBlock();
      let cointype0 = "0x2::sui::SUI";
      const txMuldyValue = Math.floor(
        +generateJsonContent?.amount * 1_000_000_000
      );
      const [coins] = tx.splitCoins(tx.gas, [tx.pure(txMuldyValue)]);
      tx.moveCall({
        target: `0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558::boltpay::create_tx`,
        typeArguments: [cointype0],
        arguments: [
          coins,
          tx.pure(generateJsonContent?.order_id),
          tx.pure(generateJsonContent?.merchant_name),
          tx.pure(generateJsonContent?.merchant_address),
          tx.pure(txMuldyValue),
        ],
      });
      const result = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });
      if (
        result.effects &&
        result.effects.status &&
        result.effects.status.status
      ) {
        const getStatus = result.effects.status.status;
        if (getStatus === "success") {
          onPaymentSuccess(
            generateJsonContent,
            result.digest,
            result.digest,
            generateJsonContent?.order_id
          );
        } else {
          setLoading(false);
          toastMessage("fail");
        }
      }
    } catch (error) {
      setLoading(false);
      toastMessage(error);
    }
  };

  const identifyTxn = () => {
    if (generateJsonContent.blockchain === ChainSUI) {
      if (getIsConnected !== null && getIsConnected !== undefined) {
        sendSUITxn();
      }
    } else if (generateJsonContent.blockchain === ChainAptos) {
      sendAptosTxn();
    } else if (generateJsonContent.blockchain === ChainCKTestBTC) {
      sendTestIcpTxn();
    } else if (generateJsonContent.blockchain === ChainCKBTC) {
      sendIcpTxn();
    }
  };

  const ManageRunTimeLabel = () => {
    return (
      <>
        {isLoading
          ? "Processing"
          : generateJsonContent.blockchain === ChainCKTestBTC
          ? isICPConnected
            ? "Wallet connected, Pay now"
            : "Connect your wallet to make a payment"
          : generateJsonContent.blockchain === ChainCKBTC
          ? isICPConnected
            ? "Wallet connected, Pay now"
            : "Connect your wallet to make a payment"
          : generateJsonContent.blockchain === ChainAptos
          ? connected
            ? "Wallet connected, Pay now"
            : "Connect your wallet to make a payment"
          : generateJsonContent.blockchain === ChainSUI
          ? currentAccount
            ? "Wallet connected, Pay now"
            : "Connect your wallet to make a payment"
          : ""}
      </>
    );
  };

  return (
    <div className="rightWrapper">
      <div className="rightWalletWrapper">
        <p className="subHeading">
          <ManageRunTimeLabel />
        </p>
        {isLoading ? (
          <div className="paymentPendingLoader">
            <div className="loader">
              <PendingLoader />
            </div>
          </div>
        ) : (
          <>
            <div className="walletConnectWrapper">
              <p className="subInnerHeadingPara m0">
                Chain Name -{" "}
                {generateJsonContent.blockchain === ChainCKTestBTC ||
                generateJsonContent.blockchain === ChainCKBTC ? (
                  <>
                    <span className="subInnerHeading">
                      Internet Computer Protocol (ICP){" "}
                    </span>
                    <div className="appIcons" style={{ marginTop: "20px" }}>
                      <span>Pay Using </span>
                      <a
                        href={"https://wallet.bitfinity.network/"}
                        target="_blank"
                        rel="noreferrer"
                        className="textDecoration"
                      >
                        Bitfinity Wallet
                      </a>
                    </div>
                  </>
                ) : (
                  <span className="subInnerHeading">
                    {generateJsonContent.blockchain}
                  </span>
                )}
              </p>
            </div>
            {generateJsonContent.blockchain === ChainSUI ? (
              <SuiWalletConnect identifyTxn={identifyTxn} />
            ) : generateJsonContent.blockchain === ChainCKTestBTC ||
              generateJsonContent.blockchain === ChainCKBTC ? (
              <IcpWalletConnect
                identifyTxn={identifyTxn}
                setIsICPConnected={setIsICPConnected}
                isICPConnected={isICPConnected}
              />
            ) : generateJsonContent.blockchain === ChainAptos ? (
              connected ? (
                <>
                  <div className="paynowBtns">
                    <Button
                      onClick={() => disconnect()}
                      className="connectWallet"
                    >
                      Disconnet
                    </Button>
                    <Button
                      className="active commonButton"
                      onClick={() => identifyTxn()}
                    >
                      Pay Now
                      <WalletOutlined />
                    </Button>
                  </div>
                </>
              ) : (
                <WalletSelector />
              )
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PayWithWallet;
