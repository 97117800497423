import { Button, Form, Input, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ChainAptos,
  ChainCKBTC,
  ChainCKTestBTC,
  ChainSOL,
  ChainSUI,
  ChainSolanaUSDC,
  ChainVenom,
  shortenValue,
  toastMessage,
} from "utils/helper";
import CoinUsingComponent from "../PayUsingComponent/CoinUsingComponent";
import { useHistory } from "react-router-dom";
import DialPaid from "components/DialPaid";

const ChainSelectionComponent = ({
  businessContent,
  cusEmail,
  setCusEmail,
  setShowResPayUsing,
  isSelectChain,
  showResPayUsing,
  setIsSelectChain,
  setIsSuiChain,
  setChainName,
  chainName,
  isSuiChain,
  setAmount,
  amount,
  data,
  merchantContent,
  manageChainAmount,
  onGenerateQrContent,
}: any) => {
  const [selectedCoin, setSelectedCoin] = useState<any>({});
  const [confirmCoin, setConfirmCoin] = useState<any>({});
  const [showInputBox, setShowInputBox] = useState(false);
  const [newAmount,setNewAmount] = useState("0");
  const history = useHistory();
  const { Option } = Select;

  const onPaynow = () => {
    if (chainName === ChainSUI) {
      const suiAmount = shortenValue(Number(newAmount) / manageChainAmount.sui);
      setAmount(suiAmount);
      setIsSelectChain(true);
      onGenerateQrContent(suiAmount);
    } else if (chainName === ChainAptos) {
      const aptosAmount = shortenValue(Number(newAmount) / manageChainAmount.aptos);
      setAmount(aptosAmount);
      setIsSelectChain(true);
      onGenerateQrContent(aptosAmount);
    } else if (chainName === ChainVenom) {
      const venomAmount = shortenValue(Number(newAmount));
      setAmount(venomAmount);
      setIsSelectChain(true);
      onGenerateQrContent(venomAmount);
    } else if (chainName === ChainSOL) {
      const solAmount = shortenValue(Number(newAmount) / manageChainAmount.solana);
      setAmount(solAmount);
      setIsSelectChain(true);
      onGenerateQrContent(solAmount);
    } else if (chainName === ChainSolanaUSDC) {
      const solUsdcAmount = shortenValue(Number(newAmount));
      setAmount(solUsdcAmount);
      setIsSelectChain(true);
      onGenerateQrContent(solUsdcAmount);
    } else if (chainName === ChainCKBTC) {
      const ckbtcAmount = (Number(newAmount) / manageChainAmount.ckbtc).toFixed(8);
      setAmount(ckbtcAmount);
      setIsSelectChain(true);
      onGenerateQrContent(ckbtcAmount);
    } else if (chainName === ChainCKTestBTC) {
      const ckbtcAmount = (Number(newAmount) / manageChainAmount.ckbtc).toFixed(8);
      setAmount(ckbtcAmount);
      setIsSelectChain(true);
      onGenerateQrContent(ckbtcAmount);
    } else {
      toastMessage("comming soon");
    }
  };

  const onVerifyPay = () => {
    if (newAmount !== "" && newAmount !== "0") {
      if (Object.keys(selectedCoin).length === 0) {
        toastMessage("Please select Blockchain");
      } else if (Object.keys(confirmCoin).length === 0) {
        toastMessage("Please select coin");
      } else if (Object.keys(confirmCoin).length > 0) {
        if (merchantContent && merchantContent.order_type === "POS") {
          if (cusEmail !== "" && cusEmail !== " ") {
            onPaynow();
            setShowResPayUsing(false);
          } else {
            toastMessage("First, please enter email address");
          }
        } else {
          onPaynow();
          setShowResPayUsing(false);
        }
      }
    } else {
      toastMessage("Please enter amount");
    }
  };

  const onNextEvent = () => {
    if (Object.keys(selectedCoin).length === 0) {
      toastMessage("Please select Blockchain");
    } else {
      setShowResPayUsing(true);
    }
  };

  useEffect(() => {
    if (!isSelectChain) {
      setSelectedCoin({});
      setShowInputBox(false);
    }
  }, [isSelectChain]);

  useEffect(() => {
    if (Object.keys(confirmCoin).length > 0) {
      setShowInputBox(true);
    }
  }, [confirmCoin]);

  return (
    <>
      <ToastContainer />
      <div className="paymentOptionsWrapper">
        <div className="paymentMethodSelections">
          <h5>Payment With PocketTerminal</h5>
          <Select className="paymentGridWrapper">
            <Option value="" disabled>
              Please select blockchain
            </Option>
            {data.map((item: any) => (
              <Option
                className={
                  selectedCoin &&
                  selectedCoin.blockchain_name === item.blockchain_name
                    ? "paymentBlockWrapper active"
                    : "paymentBlockWrapper"
                }
                value={item.blockchain_name}
                key={item.blockchain_name}
              >
                <div
                  className="flexWrapper"
                  onClick={() => {
                    setSelectedCoin(item);
                    setChainName(item.blockchain_name);
                  }}
                >
                  <p className="paymentBlockText">{item.blockchain_name}</p>
                </div>
              </Option>
            ))}
          </Select>
          <div className="payNowSliderBtn">
            <button className="commonButton" onClick={() => onNextEvent()}>
              Next
            </button>
          </div>
        </div>
        {Object.keys(selectedCoin).length > 0 && (
          <div
            className={
              showResPayUsing ? "paymentMethods displayBlock" : "paymentMethods"
            }
          >
            <p>Select Coin</p>
            <div className="conversionRateWrapper">
              <CoinUsingComponent
                orderPrice={amount}
                chainPrice={manageChainAmount}
                selectedChain={selectedCoin}
                setChainName={setChainName}
                confirmCoin={confirmCoin}
                setConfirmCoin={setConfirmCoin}
              />
            </div>
            {showInputBox &&
              merchantContent &&
              merchantContent.order_type === "POS" && (
                <Form initialValues={{ remember: true }} layout="vertical">
                  <Form.Item
                    label=" "
                    rules={[{ required: true, message: "Please input name!" }]}
                  >
                    <Input
                      placeholder="Email Address"
                      value={cusEmail}
                      onChange={(e) => setCusEmail(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              )}
          </div>
        )}
        <DialPaid setNewAmount={setNewAmount} newAmount={newAmount} />
        <div className="methodButtonWrapper">
          <Button onClick={() => onVerifyPay()} className="commonButton">
            Generate Payment
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChainSelectionComponent;
