import { ConnectModal, useWalletKit } from "@mysten/wallet-kit";
import { Button } from "antd";
import React, { useState } from "react";
import { CheckIcon } from "svgIcon";
import { WalletOutlined } from "@ant-design/icons";
import "./style.css";

const SuiWalletConnect = ({ identifyTxn }: any) => {
  const { currentAccount, disconnect } = useWalletKit();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const ButtonDisconnect = () => {
    const keys = currentAccount?.address;
    const firstFour = keys?.substring(0, 4);
    const lastFour = keys?.slice(keys?.length - 4);
    const result = firstFour + "..." + lastFour;

    const walletDisconnect = () => {
      disconnect();
      setShowConnectModal(false);
    };

    return (
      <>
        <div className="paynowBtns">
          <Button className="connectWallet" onClick={() => walletDisconnect()}>
            {result}
            <CheckIcon />
          </Button>
          <Button className="active commonButton" onClick={() => identifyTxn()}>
            Pay Now
            <WalletOutlined />
          </Button>
        </div>
      </>
    );
  };

  const ButtonConnect = () => {
    if (currentAccount) {
      return <ButtonDisconnect />;
    }

    return (
      <>
        <ConnectModal
          open={showConnectModal}
          onClose={() => setShowConnectModal(false)}
        />
        <Button
          className="active commonButton"
          onClick={() => setShowConnectModal(true)}
        >
          Connect & Pay
        </Button>
      </>
    );
  };
  return <ButtonConnect />;
};

export default SuiWalletConnect;
