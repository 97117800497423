import React from "react";

const DialPaid = ({ setNewAmount, newAmount }: any) => {
  const handleButtonClick = (char: any) => {
    if (char === "x") {
      setNewAmount((prev: any) => prev.slice(0, -1) || "0");
    } else if (char === ".") {
      if (!newAmount.includes(".")) {
        setNewAmount((prev: any) => prev + char);
      }
    } else if (char === "0") {
      if (newAmount !== "0") {
        setNewAmount((prev: any) => (prev.length < 8 ? prev + char : prev));
      }
    } else {
      if (newAmount === "0") {
        setNewAmount(char);
      } else {
        setNewAmount((prev: any) => (prev.length < 8 ? prev + char : prev));
      }
    }
    // onamountChange?.(amount);
  };

  return (
    <div className="amountDisplay">
      <div className="text">Enter amount in</div>
      <div className="amount">{newAmount}</div>
      <div className="buttons">
        {[
          ["1", "2", "3"],
          ["4", "5", "6"],
          ["7", "8", "9"],
          [".", "0", "x"],
        ].map((row, rowIndex) => (
          <div className="row" key={rowIndex}>
            {row.map((char) => (
              <button
                className="amountButton"
                key={char}
                onClick={() => handleButtonClick(char)}
              >
                {char}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DialPaid;
