import FooterComponent from "components/Footer";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { SuccessLoader } from "svgIcon";
import {
  checkCoin,
} from "utils/helper";

const CompeletedOrderComponent = ({ completedContent, businessContent }: any) => {
  const [url, setUrl] = useState("");

  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(completedContent.coin, getDigest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  useEffect(() => {
    onRenderAddress(completedContent.digest);
  }, [completedContent]);

  return (
    <div className="qrContentWrapper radius10 padd30 boxShadow extraCompletedCss">
      <ToastContainer />
      <div className="successHeader">
        <div className="successAnimation">
          <SuccessLoader />
        </div>
        <p className="mainHeading">
          Your order has already <br />
          been placed
        </p>
      </div>
      <FooterComponent />
    </div>
  );
};

export default CompeletedOrderComponent;
