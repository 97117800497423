import { useEffect, useState } from "react";
import {
  ChainAptos,
  ChainICP,
  ChainSOL,
  ChainSUI,
  ChainSolana,
  ChainSolanaUSDC,
  ChainVenom,
  selectedChainIcon,
  shortenValue,
} from "utils/helper";

const CoinUsingComponent = ({
  orderPrice,
  chainPrice,
  selectedChain,
  setChainName,
  confirmCoin,
  setConfirmCoin,
}: any) => {
  const [showConversion, setShowConversion] = useState(false);
  const [runTimeSelectedName, setRunTimeSelectedChain] = useState("");
  const [chainContent, setChainContent] = useState({
    selectedChainPrice: "",
    SelectedChainAmount: "",
    selectedChainFormula: "",
  });
  const hasSubCoins =
    selectedChain &&
    selectedChain.sub_coin &&
    selectedChain.sub_coin.length > 0;

  const manageSubCoinValues = (getCoinName: any) => {
    switch (getCoinName) {
      case ChainSOL: {
        return setChainContent({
          selectedChainPrice: chainPrice.solana,
          SelectedChainAmount: shortenValue(orderPrice / chainPrice.solana),
          selectedChainFormula: `(${orderPrice} / ${
            chainPrice.solana
          } = ${shortenValue(orderPrice / chainPrice.solana)})`,
        });
      }
      case ChainSolanaUSDC: {
        return setChainContent({
          selectedChainPrice: "1",
          SelectedChainAmount: orderPrice,
          selectedChainFormula: `(${orderPrice})`,
        });
      }
    }
  };

  const manageMainCoinValues = (getCoinName: any) => {
    switch (getCoinName) {
      case ChainSUI: {
        return setChainContent({
          selectedChainPrice: chainPrice.sui,
          SelectedChainAmount: shortenValue(orderPrice / chainPrice.sui),
          selectedChainFormula: `(${orderPrice} / ${
            chainPrice.sui
          } = ${shortenValue(orderPrice / chainPrice.sui)})`,
        });
      }
      case ChainAptos: {
        return setChainContent({
          selectedChainPrice: chainPrice.aptos,
          SelectedChainAmount: shortenValue(orderPrice / chainPrice.aptos),
          selectedChainFormula: `(${orderPrice} / ${
            chainPrice.aptos
          } = ${shortenValue(orderPrice / chainPrice.aptos)})`,
        });
      }
      case ChainVenom: {
        return setChainContent({
          selectedChainPrice: "1",
          SelectedChainAmount: orderPrice,
          selectedChainFormula: `(${orderPrice})`,
        });
      }
      case ChainSolana: {
        return setChainContent({
          selectedChainPrice: chainPrice.solana,
          SelectedChainAmount: shortenValue(orderPrice / chainPrice.solana),
          selectedChainFormula: `(${orderPrice} / ${
            chainPrice.solana
          } = ${shortenValue(orderPrice / chainPrice.solana)})`,
        });
      }
      case ChainICP: {
        return setChainContent({
          selectedChainPrice: chainPrice.ckbtc,
          SelectedChainAmount: (orderPrice / chainPrice.ckbtc).toFixed(8),
          selectedChainFormula: `(${orderPrice} / ${chainPrice.ckbtc} = ${(
            orderPrice / chainPrice.ckbtc
          ).toFixed(8)})`,
        });
      }
    }
  };

  const subCoinRender = (getName: any) => {
    if (getName === ChainSOL) {
      return <span className="extraRate">{chainPrice.solana}</span>;
    } else if (getName === ChainSolanaUSDC) {
      return <span className="extraRate">$ {1}</span>;
    } else {
      return (
        <span className="extraRate">
          {`$ ${chainContent.selectedChainPrice}`}
        </span>
      );
    }
  };

  useEffect(() => {
    if (
      runTimeSelectedName === ChainSOL ||
      runTimeSelectedName === ChainSolanaUSDC
    ) {
      manageSubCoinValues(runTimeSelectedName);
    }
  }, [runTimeSelectedName]);

  useEffect(() => {
    setConfirmCoin({});
    setShowConversion(false);
    manageMainCoinValues(selectedChain && selectedChain.blockchain_name);
    setRunTimeSelectedChain(selectedChain && selectedChain.blockchain_name);
  }, [selectedChain]);

  return (
    <>
      <div className="conversionHeaderWrapper">
        {!hasSubCoins && selectedChain && (
          <div
            className={
              Object.keys(confirmCoin).length > 0 &&
              confirmCoin.blockchain_name === runTimeSelectedName
                ? "headerWrapper active"
                : "headerWrapper"
            }
            onClick={() => {
              setShowConversion(true);
              setChainName(selectedChain.blockchain_name);
              setRunTimeSelectedChain(selectedChain.blockchain_name);
              setConfirmCoin(selectedChain);
            }}
          >
            <div className="cryptoMethodIcon">
              {selectedChainIcon(selectedChain.blockchain_name)}
            </div>
            <div className="conversionRateData">
              <p className="extraRateText">{selectedChain.blockchain_name}</p>
              <span className="extraRate">
                {chainContent.selectedChainPrice === "-"
                  ? chainContent.selectedChainPrice
                  : `$ ${chainContent.selectedChainPrice}`}
              </span>
            </div>
          </div>
        )}
        {hasSubCoins &&
          selectedChain.sub_coin.map((subCoin: any) => (
            <div
              key={subCoin.id}
              className={
                Object.keys(confirmCoin).length > 0 &&
                subCoin.blockchain_name === runTimeSelectedName
                  ? "headerWrapper active"
                  : "headerWrapper"
              }
              onClick={() => {
                setShowConversion(true);
                setChainName(subCoin.blockchain_name);
                setRunTimeSelectedChain(subCoin.blockchain_name);
                setConfirmCoin(subCoin);
              }}
            >
              <div className="cryptoMethodIcon">
                {selectedChainIcon(subCoin.blockchain_name)}
              </div>
              <div className="conversionRateData">
                <p className="extraRateText">{subCoin.blockchain_name}</p>
                {subCoinRender(subCoin.blockchain_name)}
              </div>
            </div>
          ))}
      </div>
      {showConversion ? (
        <>
          {/* <div className="conversionConetntWrapper">
            <p className="conversionConetntHeading">Conversion Rate</p>
            <div className="contentDetailWrapper">
              <p>Order Price:</p>
              <p>$ {orderPrice}</p>
            </div>
            <div className="contentDetailWrapper">
              <p>{runTimeSelectedName} Price:</p>
              <p>$ {chainContent.selectedChainPrice}</p>
            </div>
            <div className="contentDetailTotalWrapper">
              <div className="contentTotal">
                <p>Total: </p>
                <p>{chainContent.SelectedChainAmount}</p>
              </div>
              <span className="contentFormula">
                {chainContent.selectedChainFormula}
              </span>
            </div>
          </div> */}
          {/* <div className="conversionConetntWrapper">
            <p className="conversionConetntHeading">Order Summary</p>
            <div className="contentDetailWrapper">
              <p>Order Total:</p>
              <p>{chainContent.SelectedChainAmount}</p>
            </div>
            <div className="contentDetailWrapper">
              <p>Gas Fee: </p>
              <p> - </p>
            </div>
            <div className="contentTotalWrapper">
              <div className="contentTotal">
                <p>Total: </p>
                <p>
                  {chainContent.SelectedChainAmount} {runTimeSelectedName}
                </p>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <></>
        // <div className="conversionConetntWrapper">
        //   <p className="conversionConetntHeading">Order Summary</p>
        //   <div className="contentDetailWrapper">
        //     <p>Order Total:</p>
        //     <p>{"-"}</p>
        //   </div>
        //   <div className="contentDetailWrapper">
        //     <p>Gas Fee: </p>
        //     <p> - </p>
        //   </div>
        //   <div className="contentTotalWrapper">
        //     <div className="contentTotal">
        //       <p>Total: </p>
        //       <p>-</p>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default CoinUsingComponent;
