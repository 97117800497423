import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { WalletKitProvider } from "@mysten/wallet-kit";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";
import "./App.css";
import "./assets/global.css";
import { GlobalMerchantProvider } from "utils/helper";
// import NotFound from "components/NotFound";
import NotFound from "components/NotFound";
import NumPayContainer from "containers/NumPayContainer";

const wallets = [new PetraWallet(), new MartianWallet()];
function App() {
  localStorage.setItem("userTheme", "light");
  return (
    <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
      <WalletKitProvider
        features={["sui:signTransactionBlock"]}
        enableUnsafeBurner
      >
        <GlobalMerchantProvider>
          <Router>
            <Switch>
              <Route path="/pos-terminal/:id" component={NumPayContainer} />
              <Route path="/" component={NotFound} />
            </Switch>
          </Router>
        </GlobalMerchantProvider>
      </WalletKitProvider>
    </AptosWalletAdapterProvider>
  );
}

export default App;
