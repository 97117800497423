import React, { useEffect, useRef, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { PublicKey, Keypair, Connection } from "@solana/web3.js";
import {
  findReference,
  validateTransfer,
  encodeURL,
  createQR,
} from "@solana/pay";
import BigNumber from "bignumber.js";
import axios from "axios";
import {
  endOfTime,
  networkUrl,
  packageId,
} from "containers/CheckoutContainer/constant";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import PaymentVerifyBoxComponent from "../PaymentVerifyBoxComponent";
import { baseURL } from "utils/env";
import {
  ChainAptos,
  ChainCKTestBTC,
  ChainSOL,
  ChainSUI,
  ChainSolanaUSDC,
  ChainVenom,
  dateAndTimeFormat,
  decodeContent,
  extractID,
  toastMessage,
} from "utils/helper";
import { Image } from "antd";
import { AppleIcon, PlayStoreIcon } from "svgIcon";

const PaymentQRComponent = ({
  cusEmail,
  generateJsonContent,
  qrContentTitle,
  setIsPaymentSuccess,
  setManageDigest,
  merchantContent,
}: any) => {
  const [qrStatus, setQrStatus] = useState(0);
  const [qrKey, setQrKey] = useState({});
  const [qrString, setQrString] = useState("");
  const [showTiming, setShowTiming] = useState(endOfTime);
  const refIsReloadInProgress = useRef(false);
  const [showTxnInput, setShowTxnInput] = useState(false);
  const [qrCodeURL, setQrCodeURL] = useState("");

  const onTerminalStore = async (content: any, digest: any, sender: any) => {
    const getDateAndTime = await dateAndTimeFormat();
    const merchantObject = {
      amount: content.amount,
      name: " ",
      client_address:
        "0x9d655392521726d0eb26915670f7a37fe78b6fe001d133280ddaf57e4428aae1",
      coin: content.blockchain,
      coin_amount: content.amount,
      payment_id: content.order_id,
      date_time: `${getDateAndTime}`,
      digest: digest,
      status: "completed",
      order_type: "Terminal",
      merchant_id: merchantContent.merchant_id,
      merchant_email: merchantContent.merchant_email,
      merchant_name: merchantContent.merchant_name,
      merchant_address: " ",
      network: " ",
      business_name: "demo business",
      customer_email: cusEmail,
    };
    if (merchantObject) {
      try {
        axios
        .post(`${baseURL}merchant_orders`, merchantObject)
          .then((response) => {
            setManageDigest(digest);
            setIsPaymentSuccess(true);
          });
      } catch (error) {}
    }
  };

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    // setQrStatus(5);
    onTerminalStore(content, digest, sender);
  };

  let checkData = 1;
  let timeCheck = endOfTime;

  const onfetchLastSuiTransaction = (content: any, randomKey: any) => {
    if (refIsReloadInProgress.current) {
      return;
    }
    refIsReloadInProgress.current = true;

    const data = {
      method: "suix_queryTransactionBlocks",
      jsonrpc: "2.0",
      params: [
        {
          filter: {
            InputObject: packageId,
          },
          options: {
            showEffects: true,
            showInput: true,
          },
        },
        null,
        1,
        true,
      ],
      id: "1",
    };

    try {
      axios
        .post(networkUrl, data)
        .then(({ data }) => {
          const { result } = data;
          if (result) {
            const { data } = result;
            if (
              data &&
              data[0] &&
              data[0].transaction &&
              data[0].transaction.data.transaction.inputs.length === 5 &&
              data[0].transaction.data.transaction.inputs[1].value ===
                randomKey.toString() &&
              data[0].transaction.data.transaction.inputs[4].value ==
                content.amount * 1000000000 &&
              data[0].transaction.data.transaction.inputs[3].value ==
                content.merchant_address
            ) {
              if (
                data &&
                data[0] &&
                data[0].effects &&
                data[0].effects.status &&
                data[0].effects.status.status == "success"
              ) {
                // setIsSuccess(true);
                onPaymentSuccess(
                  content,
                  data[0].digest,
                  data[0].transaction.data.sender,
                  randomKey
                );
                checkData += 1;
              } else {
                setQrStatus(3);
              }
            } else {
              if (checkData == 1 && timeCheck !== 0) {
                timeCheck -= 1;
                setShowTiming(timeCheck);
                setTimeout(() => {
                  onfetchLastSuiTransaction(content, randomKey);
                }, 1500);
              } else {
                setQrStatus(4);
              }
            }
          }
        })
        .catch((error: any) => {
          console.log("OOPS! The SUI network may be down");
        });
    } catch {
      console.log("OOPS! The SUI network may be down");
    }
    refIsReloadInProgress.current = false;
  };

  const onfetchLastAptosTransaction = async (content: any, randomKey: any) => {
    if (refIsReloadInProgress.current) {
      return;
    }
    refIsReloadInProgress.current = true;

    try {
      const { data: version } = await axios.get(
        `https://fullnode.testnet.aptoslabs.com/v1/accounts/${generateJsonContent.merchant_address}/events/0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>/deposit_events`
      );
      // Fetch the latest transaction hash
      const reversedVersion = version.reverse();
      const checkVersion = reversedVersion?.[0]?.version;
      const { data: txnData } = await axios.get(
        `https://fullnode.testnet.aptoslabs.com/v1/transactions/by_version/${checkVersion}`
        // `https://fullnode.testnet.aptoslabs.com/v1/accounts/0xef3a1a1ab3915aed6490a917d5b97ef32568f9c5772e35f44fc4de67a625f874/transactions`
      );
      const latestTransactionHash = txnData?.hash;
      if (!latestTransactionHash) {
        throw new Error("Failed to fetch latest transaction hash");
      }
      // Fetch transaction details
      const { data: txnDetailsData } = await axios.get(
        `https://fullnode.testnet.aptoslabs.com/v1/transactions/by_hash/${latestTransactionHash}`
      );
      refIsReloadInProgress.current = false;
      if (
        txnDetailsData.payload?.arguments?.[2] == content.amount * 100000000 &&
        txnDetailsData.payload?.arguments?.[0] == randomKey.toString() &&
        txnDetailsData.payload?.arguments?.[3] == content.merchant_name
      ) {
        onPaymentSuccess(
          content,
          latestTransactionHash,
          "738627854897905867679889",
          randomKey
        );
        checkData += 1;
      } else {
        if (checkData == 1 && timeCheck !== 0) {
          timeCheck -= 1;
          setShowTiming(timeCheck);
          setTimeout(() => {
            onfetchLastAptosTransaction(content, randomKey);
          }, 1500);
        } else {
          setQrStatus(4);
        }
      }
    } catch (error) {
      setQrStatus(4);
    } finally {
      refIsReloadInProgress.current = false;
    }
  };

  const onfetchHashVenomTransaction = async (
    content: any,
    digest: any,
    randomKey: any
  ) => {
    try {
      axios
        .get(`https://testnetverify.venomscan.com/parse/transaction/${digest}`)
        .then(({ data }) => {
          if (data && data[0] && data[0].parsed_data) {
            if (
              data[0].parsed_data &&
              data[0].parsed_data._merchant_id == randomKey &&
              data[0].parsed_data._merchant_amount == parseInt(content.amount)
            ) {
              onPaymentSuccess(
                content,
                digest,
                "738627854897905867679889",
                randomKey
              );
              checkData += 1;
            } else {
              if (checkData == 1 && timeCheck !== 0) {
                timeCheck -= 1;
                setShowTiming(timeCheck);
                setTimeout(() => {
                  onfetchLastVenomTransaction(content, randomKey);
                }, 1500);
              } else {
                setQrStatus(4);
              }
            }
          } else {
            console.log("OOPS!! The VENOM network may be down");
          }
        });
    } catch (error) {
      console.log("OOPS!! The VENOM network may be down");
    }
  };

  const onfetchLastVenomTransaction = (content: any, randomKey: any) => {
    if (refIsReloadInProgress.current) {
      return;
    }
    refIsReloadInProgress.current = true;

    const data = {
      includeAccounts: [
        "0:213383059e1bb9a26923610a26dbaa25cc122263dc1b3a4c90f5a05d12d9b232", //contract address - venom
      ],
      txTypes: ["Ordinary"],
      limit: 1,
      offset: 0,
      ordering: {
        column: "time",
        direction: "DESC",
      },
    };
    try {
      axios
        .post("https://testnet-api.venomscan.com/v1/transactions/list", data)
        .then(({ data }) => {
          if (data && data[0] && data[0].hash) {
            const getHash = data[0].hash;
            if (getHash !== "" && getHash !== null && getHash !== undefined) {
              onfetchHashVenomTransaction(content, getHash, randomKey);
            }
          }
        });
    } catch (error) {
      console.log("OOPS!! The VENOM network may be down");
    } finally {
      refIsReloadInProgress.current = false;
    }
  };

  const onfetchSolanaTransaction = async (
    content: any,
    randomKey: any,
    newReference: any
  ) => {
    try {
      const usdcTokenAddress = new PublicKey(
        "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr"
      );
      if (content.blockchain === ChainSOL) {
        const ankrSolRPC = "https://rpc.ankr.com/solana_devnet";
        if (!newReference) {
          toastMessage("No reference found.");
          return;
        }
        const connection = new Connection(ankrSolRPC, "confirmed");
        const signatureInfo = await findReference(connection, newReference);
        const isValid = await validateTransfer(
          connection,
          signatureInfo.signature,
          {
            recipient: new PublicKey(content.merchant_address),
            amount: new BigNumber(content.amount),
          }
        );
        if (isValid) {
          toastMessage("Payment validated successfully!");
          onPaymentSuccess(
            content,
            signatureInfo.signature,
            "738627854897905867679889",
            randomKey
          );
          checkData += 1;
        }
      } else if (content.blockchain === ChainSolanaUSDC) {
        const ankrSolRPC = "https://rpc.ankr.com/solana_devnet";
        if (!newReference) {
          toastMessage("No reference found.");
          return;
        }
        const connection = new Connection(ankrSolRPC, "confirmed");
        const signatureInfo = await findReference(connection, newReference);
        const isValid = await validateTransfer(
          connection,
          signatureInfo.signature,
          {
            recipient: new PublicKey(content.merchant_address),
            amount: new BigNumber(content.amount),
            splToken: usdcTokenAddress,
          }
        );
        if (isValid) {
          toastMessage("Payment validated successfully!");
          onPaymentSuccess(
            content,
            signatureInfo.signature,
            "738627854897905867679889",
            randomKey
          );
          checkData += 1;
        }
      }
    } catch (error) {
      if (checkData == 1 && timeCheck !== 0) {
        timeCheck -= 1;
        setShowTiming(timeCheck);
        setTimeout(() => {
          onfetchSolanaTransaction(content, randomKey, newReference);
        }, 1500);
      } else {
        setQrStatus(4);
      }
    }
  };

  const onLoadGenerateQr = async () => {
    setQrStatus(1);
    setShowTiming(endOfTime);
    setQrString("");

    const newReference = new Keypair().publicKey;
    if (generateJsonContent.blockchain === ChainSOL) {
      const recipientPublicKey = new PublicKey(
        generateJsonContent.merchant_address
      );
      let newSolanaQrPaymentObject = encodeURL({
        recipient: recipientPublicKey,
        amount: new BigNumber(generateJsonContent.amount),
        reference: newReference!,
        label: generateJsonContent.merchant_name,
        message: "Thanks for pay using Pocketpay!",
        memo: `${generateJsonContent.order_id}`,
      });

      const qr = createQR(newSolanaQrPaymentObject);
      const qrBlob = await qr.getRawData("png");
      if (!qrBlob) return;

      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof event.target?.result === "string") {
          setQrCodeURL(event.target.result);
        }
      };
      reader.readAsDataURL(qrBlob);

      // let commerceString = JSON.stringify(newSolanaQrPaymentObject);
      // setQrKey(generateJsonContent);
      // setQrString(commerceString);
      let commerceString = JSON.stringify(generateJsonContent);
      setQrKey(generateJsonContent);
      setQrString(commerceString);
    } else if (generateJsonContent.blockchain === ChainSolanaUSDC) {
      try {
        const usdcTokenAddress = new PublicKey(
          "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr"
        );
        const recipientPublicKey = new PublicKey(
          generateJsonContent.merchant_address
        );
        let newSolanaQrPaymentObject = encodeURL({
          recipient: recipientPublicKey,
          amount: new BigNumber(generateJsonContent.amount),
          splToken: usdcTokenAddress,
          reference: newReference!,
          label: generateJsonContent.merchant_name,
          message: "Thanks for pay using Pocketpay!",
          memo: `${generateJsonContent.order_id}`,
        });

        const qr = createQR(newSolanaQrPaymentObject);
        const qrBlob = await qr.getRawData("png");
        if (!qrBlob) return;

        const reader = new FileReader();
        reader.onload = (event) => {
          if (typeof event.target?.result === "string") {
            setQrCodeURL(event.target.result);
          }
        };
        reader.readAsDataURL(qrBlob);

        // let commerceString = JSON.stringify(newSolanaQrPaymentObject);
        // setQrKey(generateJsonContent);
        // setQrString(commerceString);
        let commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);
      } catch (error: any) {
        console.log(error);
      }
    } else {
      let commerceString = JSON.stringify(generateJsonContent);
      setQrKey(generateJsonContent);
      setQrString(commerceString);
    }

    if (generateJsonContent.blockchain === ChainSUI) {
      onfetchLastSuiTransaction(
        generateJsonContent,
        generateJsonContent.order_id
      );
    } else if (generateJsonContent.blockchain === ChainAptos) {
      onfetchLastAptosTransaction(
        generateJsonContent,
        generateJsonContent.order_id
      );
    } else if (generateJsonContent.blockchain === ChainVenom) {
      onfetchLastVenomTransaction(
        generateJsonContent,
        generateJsonContent.order_id
      );
    } else if (generateJsonContent.blockchain === ChainSOL) {
      onfetchSolanaTransaction(
        generateJsonContent,
        generateJsonContent.order_id,
        newReference
      );
    } else if (generateJsonContent.blockchain === ChainSolanaUSDC) {
      onfetchSolanaTransaction(
        generateJsonContent,
        generateJsonContent.order_id,
        newReference
      );
    } else if (generateJsonContent.blockchain === ChainCKTestBTC) {
      // onfetchSolanaTransaction(
      //   generateJsonContent,
      //   generateJsonContent.order_id,
      //   newReference
      // );
    }
  };

  const renderLinkContent = (getChainName: any) => {
    switch (getChainName) {
      case ChainSUI: {
        return (
          <>
            <p className="subHeading">Scan QR With Pocketpay App</p>
            <div className="appIcons">
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.Pocketpay.suiwallet&pli=1"
                }
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <PlayStoreIcon />
                </div>
              </a>
              <a
                href={
                  "https://apps.apple.com/in/app/Pocketpay-sui-wallet/id6463131577"
                }
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <AppleIcon />
                </div>
              </a>
            </div>
          </>
        );
      }
      case ChainAptos: {
        return (
          <>
            <span>Scan QR With Pocketpay Web3 App</span>
            <div className="appIcons">
              <a
                href={"https://aptos.wallet.Pocketpay.io/"}
                target="_blank"
                rel="noreferrer"
                className="textDecoration"
              >
                Web3 Wallet
              </a>
            </div>
          </>
        );
      }
      case ChainVenom: {
        return (
          <>
            <span>Scan QR With Pocketpay Web3 App</span>
            <div className="appIcons">
              <a
                href={"https://venom.wallet.Pocketpay.io/"}
                target="_blank"
                rel="noreferrer"
                className="textDecoration"
              >
                Web3 Wallet
              </a>
            </div>
          </>
        );
      }
      case ChainSOL: {
        return (
          <>
            <span>Scan QR With </span>
            <div className="appIcons">
              <a
                href={"https://phantom.app/download"}
                target="_blank"
                rel="noreferrer"
                className="textDecoration"
              >
                Phantom Wallet
              </a>
            </div>
          </>
        );
      }
      case ChainSolanaUSDC: {
        return (
          <>
            <span>Scan QR With </span>
            <div className="appIcons">
              <a
                href={"https://phantom.app/download"}
                target="_blank"
                rel="noreferrer"
                className="textDecoration"
              >
                Phantom Wallet
              </a>
            </div>
          </>
        );
      }
    }
  };

  useEffect(() => {
    onLoadGenerateQr();
  }, []);
  return (
    <>
      <div className="rightWrapper">
        <div className="rightQrWrapper">
          <div className="qrWrapperContent">
            {qrString !== "" && (
              <>
                {qrStatus === 4 ? (
                  <div className="qrWrapperContentInner">
                    <PaymentCouldntFetchComponent
                      qrKey={qrKey}
                      onLoadGenerateQr={onLoadGenerateQr}
                      setShowTxnInput={setShowTxnInput}
                    />
                  </div>
                ) : (
                  <>
                    <div className="chainName">
                      <p className="subInnerHeadingPara">
                        Chain Name -{" "}
                        <span className="subInnerHeading">
                          {generateJsonContent.blockchain}
                        </span>
                      </p>
                    </div>
                    <>
                      {generateJsonContent.blockchain === ChainSOL ||
                      generateJsonContent.blockchain === ChainSolanaUSDC ? (
                        <Image
                          className="qr-code"
                          src={qrCodeURL}
                          alt="Solana Pay QR Code"
                          width={200}
                          height={200}
                        />
                      ) : (
                        <QRCodeCanvas
                          className="qrCanvasStyle"
                          value={qrString}
                          size={200}
                        />
                      )}
                    </>
                    <p className="subInnerHeadingPara">
                      Ends in {showTiming} seconds
                    </p>
                    <div className="appDownloadWrapper">
                      {renderLinkContent(generateJsonContent.blockchain)}
                    </div>
                  </>
                )}
              </>
            )}
            <>
              {qrStatus !== 1 &&
                showTxnInput &&
                generateJsonContent.blockchain !== ChainSOL &&
                generateJsonContent.blockchain !== ChainSolanaUSDC &&
                generateJsonContent.blockchain !== ChainVenom && (
                  <PaymentVerifyBoxComponent
                    onPaymentSuccess={onPaymentSuccess}
                    qrKey={qrKey}
                    setQrStatus={setQrStatus}
                    setManageDigest={setManageDigest}
                  />
                )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentQRComponent;
