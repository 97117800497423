import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { WalletOutlined } from "@ant-design/icons";

const IcpWalletConnect = ({
  identifyTxn,
  isICPConnected,
  setIsICPConnected,
}: any) => {
  useEffect(() => {
    const checkConnection = async () => {
      if (window.ic && window.ic.bitfinityWallet) {
        const connected = await window.ic.bitfinityWallet.isConnected();
        setIsICPConnected(connected);
      }
    };
    checkConnection();
  }, []);

  const connectWallet = async () => {
    if (window.ic && window.ic.bitfinityWallet) {
      try {
        await window.ic.bitfinityWallet.requestConnect();
        setIsICPConnected(true);
      } catch (error) {
        console.error("Connection declined:", error);
      }
    }
  };

  const disconnectWallet = async () => {
    if (window.ic && window.ic.bitfinityWallet) {
      try {
        const dis = await window.ic.bitfinityWallet.disconnect();
        console.log(dis);
        setIsICPConnected(false);
      } catch (error) {
        console.error("Connection declined:", error);
      }
    }
  };

  return (
    <div className="connectedWallet">
      {isICPConnected ? (
        <>
          <div className="paynowBtns">
            <Button
              className="connectWallet"
              onClick={() => disconnectWallet()}
            >
              Disconnect
            </Button>
            <Button
              className="active commonButton"
              onClick={() => identifyTxn()}
            >
              Pay Now
              <WalletOutlined />
            </Button>
          </div>
        </>
      ) : (
        <Button onClick={connectWallet} className="active commonButton">
          Connect & Pay
        </Button>
      )}
    </div>
  );
};

export default IcpWalletConnect;
