import React, { useEffect, useState } from "react";
import PayWithWallet from "../PayWithWallet";
import PaymentQRComponent from "../PayWithQR/PaymentQRComponent";
import { BackIcon } from "svgIcon";
import { Button } from "antd";
import {
  ChainCKBTC,
  ChainCKTestBTC,
  ChainSOL,
  ChainSolanaUSDC,
  ChainVenom,
} from "utils/helper";

const PayOptionsComponent = ({
  cusEmail,
  fetchData,
  setManageDigest,
  setIsSelectChain,
  payWithWallet,
  setIsPaymentSuccess,
  qrContent,
  contentTitle,
  amount,
  setPayWithWallet,
  merchantContent,
  manageChainAmount,
}: any) => {
  const [ckBtcValue, setCkBtcValue] = useState(0);
  useEffect(() => {
    if (manageChainAmount && manageChainAmount.ckbtc) {
      setCkBtcValue(manageChainAmount.ckbtc);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(qrContent).length > 0) {
      if (qrContent.blockchain === ChainCKTestBTC) {
        setPayWithWallet(true);
      }
      if (qrContent.blockchain === ChainCKBTC) {
        setPayWithWallet(true);
      }
    }
  }, [qrContent]);

  return (
    <>
      <div className="rightMainHeader">
        <div
          onClick={() => {
            fetchData();
            setIsSelectChain(false);
            setPayWithWallet(false);
          }}
          className="cursorPointer"
        >
          <BackIcon />
        </div>
        <p className="mainHeading">Pay For Your Order</p>
      </div>
      <div className="rightMainBody radius10 boxShadow padd30">
        <div>
          {payWithWallet ? (
            <PayWithWallet
              cusEmail={cusEmail}
              setIsPaymentSuccess={setIsPaymentSuccess}
              setManageDigest={setManageDigest}
              generateJsonContent={qrContent}
              qrContentTitle={contentTitle}
              merchantContent={merchantContent}
            />
          ) : (
            <PaymentQRComponent
              cusEmail={cusEmail}
              setIsPaymentSuccess={setIsPaymentSuccess}
              setManageDigest={setManageDigest}
              generateJsonContent={qrContent}
              qrContentTitle={contentTitle}
              merchantContent={merchantContent}
            />
          )}
        </div>
      </div>
      <div className="rightMainContent">
        <div className="rightMainContentHeader">
          {Object.keys(qrContent).length > 0 && (
            <>
              <p className="subHeading">{`${amount} ${
                qrContent?.blockchain
                // (qrContent && qrContent?.blockchain === ChainCKTestBTC) ||
                // qrContent?.blockchain === ChainCKBTC
                //   ? `SATS (${(merchantContent.amount / ckBtcValue).toFixed(
                //       8
                //     )} ckBTC)`
                //   : qrContent?.blockchain
              }`}</p>
            </>
          )}
          <div className="networkName">
            <p className="subInnerHeadingPara">
              Network -{" "}
              <span className="subInnerHeading">
                {qrContent?.blockchain === ChainCKBTC
                  ? "Mainnet"
                  : qrContent.blockchain !== ChainSOL &&
                    qrContent.blockchain !== ChainSolanaUSDC
                  ? "Testnet"
                  : "Devnet"}
              </span>
            </p>
          </div>
        </div>
        <div className="addressName">
          <p className="subInnerHeadingPara">Merchant Address</p>
          <p className="subHeading">
            {qrContent && qrContent?.merchant_address}
          </p>
        </div>
      </div>
      {qrContent &&
        qrContent?.blockchain !== ChainVenom &&
        qrContent?.blockchain !== ChainSOL &&
        qrContent?.blockchain !== ChainCKBTC &&
        qrContent?.blockchain !== ChainCKTestBTC &&
        qrContent?.blockchain !== ChainSolanaUSDC && (
          <div className="payWithOption">
            <p className="mainHeading">OR</p>
            {payWithWallet ? (
              <Button
                onClick={() => setPayWithWallet(false)}
                className="commonButtonSmall"
              >
                Pay With QR
              </Button>
            ) : (
              <Button
                onClick={() => setPayWithWallet(true)}
                className="commonButtonSmall"
              >
                Pay With Wallet
              </Button>
            )}
          </div>
        )}
    </>
  );
};

export default PayOptionsComponent;
