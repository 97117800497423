import React from "react";
import CautionLogo from "assets/images/caution.svg";
import { Image } from "antd";
import { ChainSUI } from "utils/helper";

function PaymentCouldntFetchComponent(props: any) {
  const { onLoadGenerateQr, setShowTxnInput, qrKey } = props;

  return (
    <div className="transactionFailure">
      <div className="transactionFailureHeader">
        <Image src={CautionLogo} alt="caution logo" preview={false} />
      </div>
      <div className="transactionFailureBody">
        <p className="mainHeading">We're sorry!</p>
        <p className="subHeading">We couldn't find your transaction</p>
        <h4 className="subInnerHeadingPara">
          If your payment was processed but hasn't appeared yet, please click{" "}
          <span className="mainHeadingPara">"Verify Payment"</span> Otherwise,
          click <span className="mainHeadingPara">"Refresh QR Code"</span> to
          generate a new QR Code for payment
        </h4>
      </div>
      <div className="transactionFailureFooter">
        {qrKey && qrKey.blockchain === ChainSUI && (
          <p className="" onClick={() => setShowTxnInput(true)}>
            Verify Payment
          </p>
        )}
        <p className="" onClick={() => onLoadGenerateQr()}>
          Refresh QR Code
        </p>
      </div>
    </div>
  );
}
export default PaymentCouldntFetchComponent;
