export const isClientAddress =
  "0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63";
export const isClientName = "boltpay.sui";
export const isClientChain = "sui";
export const isMerchantName = "Pocketpay Kiosk";
export const isCoinType =
  "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI";
export const isCoinName = "SUI";
export const isClientNetwork = "Testnet";
export const endOfTime = 45;
export const networkUrl = "https://wallet-rpc.testnet.sui.io/";
export const packageId =
  "0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558";
