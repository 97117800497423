import React from "react";

const NotFound = () => {
  return (
    <div className="commonBody">
      <div className="notFound">
        <p className="mainHeading">Oops! page is not found</p>
        <p className="mainHeadingPara">
          The page you are looking for doesn't exist or an other error occurred.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
