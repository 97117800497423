import React, { useEffect, useState } from "react";
import {
  isClientNetwork,
  isCoinType,
} from "containers/CheckoutContainer/constant";
import ChainSelectionComponent from "../ChainSelectionComponent";
import PaymentSuccessComponent from "components/PaymentOptions/PayWithQR/PaymentSuccessComponent";
import PayOptionsComponent from "components/PaymentOptions/PayOptionsComponent";
import {
  ChainAptos,
  ChainCKBTC,
  ChainCKTestBTC,
  ChainSOL,
  ChainSUI,
  ChainSolanaUSDC,
  ChainVenom,
  getCoinAmount,
  toastMessage,
} from "utils/helper";
import { getMerchantAddress } from "apiServices/app";
import CompeletedOrderComponent from "../CompeletedOrderComponent";
import { ToastContainer } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CancelIcon } from "svgIcon";

// pos - add to cart - get amount from local
// paylink - get amount from db

const CheckoutComponent = ({ businessContent }: any) => {
  const pathname = window.location.pathname;
  const [qrContent, setQrContent] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);
  const [merchantContent, setMerchantContent] = useState<any>({});
  const [completedContent, setCompletedContent] = useState<any>({});
  const [contentTitle, setContentTitle] = useState("");
  const [manageDigest, setManageDigest] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showResMerchant, setShowResMerchant] = useState(false);
  const [showResPayUsing, setShowResPayUsing] = useState(false);
  const [payWithWallet, setPayWithWallet] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isSelectChain, setIsSelectChain] = useState(false);
  const [isSuiChain, setIsSuiChain] = useState(true);
  const [chainName, setChainName] = useState("");
  const [chainList, setChainList] = useState([]);
  const [amount, setAmount] = useState("0");
  const [isCompletedOrder, setIsCompletedOrder] = useState(false);
  const [cusEmail, setCusEmail] = useState(" ");
  const [manageChainAmount, setManageChainAmount] = useState({
    sui: 0,
    aptos: 0,
    venom: 0,
    solana: 0,
    ckbtc: 0,
  });

  const onGenerateQrContent = (amount: any) => {
    const randomKey = Math.floor(1000000 + Math.random() * 9000000);
    const uniqueNetworks1 = chainList.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.blockchain_name === v.blockchain_name) === i
    );
    const allValues: any[] = [];
    chainList.forEach((network: any) => {
      if (network.sub_coin && network.sub_coin.length > 0) {
        for (let i = 0; i < network.sub_coin.length; i++) {
          allValues.push(network.sub_coin[i]);
        }
      }
    });
    const combinedArray = [...uniqueNetworks1, ...allValues];
    const getWalletAddress = combinedArray
      .filter(
        (item: any) => item.blockchain_name === chainName && item.wallet_address
      )
      .map((item: any) => item.wallet_address);
    if (getWalletAddress) {
      if (chainName === ChainAptos) {
        const generateContent = {
          merchant_address: getWalletAddress[0],
          merchant_name: merchantContent.business_name,
          order_id: randomKey,
          amount: amount,
          network: isClientNetwork,
          blockchain: chainName,
          coin_name: "APT",
        };
        setQrContent(generateContent);
      }
      if (chainName === ChainSUI) {
        const generateContent = {
          merchant_address: getWalletAddress[0],
          merchant_name: merchantContent.business_name,
          order_id: randomKey,
          amount: amount,
          network: isClientNetwork,
          blockchain: chainName,
          coin_name: chainName,
          coin_type: isCoinType,
        };
        setQrContent(generateContent);
      }
      if (
        chainName === ChainVenom ||
        chainName === ChainSOL ||
        chainName === ChainSolanaUSDC ||
        chainName === ChainCKBTC ||
        chainName === ChainCKTestBTC
      ) {
        const generateContent = {
          merchant_address: getWalletAddress[0],
          merchant_name: merchantContent.business_name,
          amount: amount,
          order_id: randomKey,
          network: isClientNetwork,
          blockchain: chainName,
        };
        setQrContent(generateContent);
      }
    }
  };

  const getMerchantAddresses = (getOrderData: any) => {
    // const getWalletAmount = localStorage.getItem("wallet:amount");
    // if (getWalletAmount !== null && getWalletAmount !== undefined) {
    //   getCoinAmount(setManageChainAmount);
    // }
    getCoinAmount(setManageChainAmount);
    try {
      getMerchantAddress(getOrderData.merchant_id)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.length > 0) {
            const activeNetworks = responseData.filter(
              (network: any) => network.is_active === 1
            );
            setChainList(activeNetworks);
            setMerchantContent(getOrderData);
            // setAmount(getWalletAmount! && getWalletAmount);
            setShowLoader(false);
          } else {
            toastMessage("You don't have blockchain profile");
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  const fetchData = async () => {
    if (businessContent) {
      setShowLoader(true);
      getMerchantAddresses(businessContent);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pathname]);

  return (
    <>
      {showLoader ? (
        <div className="loaderCenterWrapper">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <>
          {isCompletedOrder ? (
            Object.keys(completedContent).length > 0 && (
              <CompeletedOrderComponent
                completedContent={completedContent}
                businessContent={businessContent}
              />
            )
          ) : (
            <>
              {errorMessage === "" &&
                Object.keys(merchantContent).length > 0 && (
                  <>
                    <div className="paymentAreaWrapper">
                      <ToastContainer />
                      <div className="right">
                        <div className="checkoutHeader">
                          <div className="chLeft">
                            <div className="checkoutHeaderIcon">
                              <div
                                className="chProfile"
                                onClick={() =>
                                  setShowResMerchant(!showResMerchant)
                                }
                              >
                                {showResMerchant ? (
                                  <span>
                                    <CancelIcon />
                                  </span>
                                ) : (
                                  <img
                                    src="https://merchantapi.Pocketpay.io/uploads/image-1700741504433.jpg"
                                    alt=""
                                  />
                                )}
                                <span className="mainHeadingPara">
                                  Merchant
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <>
                          {manageChainAmount && manageChainAmount.sui !== 0 && (
                            <>
                              {isSelectChain ? (
                                Object.keys(qrContent).length > 0 && (
                                  <div className="rightMain">
                                    {isPaymentSuccess ? (
                                      <PaymentSuccessComponent
                                        businessContent={businessContent}
                                        qrKey={qrContent && qrContent}
                                        merchantContent={merchantContent}
                                        manageDigest={manageDigest}
                                        fetchData={fetchData}
                                        setIsSelectChain={setIsSelectChain}
                                        setPayWithWallet={setPayWithWallet}
                                      />
                                    ) : (
                                      <PayOptionsComponent
                                        cusEmail={cusEmail}
                                        fetchData={fetchData}
                                        setManageDigest={setManageDigest}
                                        setIsSelectChain={setIsSelectChain}
                                        payWithWallet={payWithWallet}
                                        setIsPaymentSuccess={
                                          setIsPaymentSuccess
                                        }
                                        qrContent={qrContent}
                                        contentTitle={contentTitle}
                                        amount={amount}
                                        merchantContent={merchantContent}
                                        manageChainAmount={manageChainAmount}
                                        setPayWithWallet={setPayWithWallet}
                                      />
                                    )}
                                  </div>
                                )
                              ) : (
                                <ChainSelectionComponent
                                  businessContent={businessContent}
                                  cusEmail={cusEmail}
                                  setCusEmail={setCusEmail}
                                  setShowResPayUsing={setShowResPayUsing}
                                  isSelectChain={isSelectChain}
                                  showResPayUsing={showResPayUsing}
                                  setIsSelectChain={setIsSelectChain}
                                  setIsSuiChain={setIsSuiChain}
                                  setChainName={setChainName}
                                  chainName={chainName}
                                  isSuiChain={isSuiChain}
                                  setAmount={setAmount}
                                  amount={amount}
                                  data={chainList}
                                  merchantContent={merchantContent}
                                  manageChainAmount={manageChainAmount}
                                  onGenerateQrContent={onGenerateQrContent}
                                />
                              )}
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutComponent;
