import { getMerchantPosSetting } from "apiServices/app";
import DialPaid from "components/DialPaid";
import React, { useEffect, useState } from "react";
import { toastMessage } from "utils/helper";
import "./index.css";
import CheckoutContainer from "containers/CheckoutContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NumPayContainer = () => {
  const managePathname = window.location.pathname;
  const [businessContent, setBusinessContent] = useState({});

  const getMerchantDetails = (getUserKey: any) => {
    try {
      getMerchantPosSetting(getUserKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData && responseData.success == false) {
              toastMessage("You dont have any profile");
            } else {
              localStorage.setItem(
                "terminal-email",
                responseData.merchant_email
              );
              setBusinessContent(responseData);
            }
          } else {
            toastMessage("You dont have any profile");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    try {
      const pathname = window.location.pathname;
      const modifiedInput = pathname.replace("/pos-terminal/", "");
      getMerchantDetails(modifiedInput);
    } catch (error) {
      console.log("Something went wrong");
    }
  }, [managePathname]);

  return (
    <div>
      <ToastContainer />
      {Object.keys(businessContent).length > 0 && (
        <CheckoutContainer businessContent={businessContent} />
      )}
    </div>
  );
};

export default NumPayContainer;
