/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { checkCoin } from "utils/helper";
import { SuccessLoader } from "svgIcon";
import { ToastContainer } from "react-toastify";
import FooterComponent from "components/Footer";
import { Button } from "antd";

function PaymentSuccessComponent({
  businessContent,
  qrKey,
  manageDigest,
  merchantContent,
  fetchData,
  setIsSelectChain,
  setPayWithWallet,
}: any) {
  const [url, setUrl] = useState("");
  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(qrKey.blockchain, getDigest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  useEffect(() => {
    onRenderAddress(manageDigest);
  }, []);

  return (
    <>
      <div className="qrContentWrapper radius10 padd30 boxShadow">
        <ToastContainer />
        <div className="successHeader">
          <div className="successAnimation">
            <SuccessLoader />
          </div>
          <p className="mainHeading">
            Your order has been placed <br />
            successfully
          </p>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "1.5rem auto" }}>
        <Button
          onClick={() => {
            fetchData();
            setIsSelectChain(false);
            setPayWithWallet(false);
          }}
        >
          Back To Main
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default PaymentSuccessComponent;
