import React from "react";
const FooterComponent = () => {
  return (
    <div className="footerWrapper">
      <p>
        Powered by&nbsp;
        <span className="mainHeadingPara">
          <a
            href={`https://pocketpay.finance/`}
            target="_blank"
            rel="noreferrer"
          >
            Pocketpay
          </a>
        </span>
      </p>
      <p>Decentralised Payment Protocol</p>
    </div>
  );
};

export default FooterComponent;
